import styled from "styled-components";

import { RepeatOrderStyledProps as Props } from "./RepeatOrder.types";
import CONSTANTS from "../../../config/constants";

const { BREAKPOINTS } = CONSTANTS;

const { tablet, desktop } = BREAKPOINTS;

const RepeatOrderStyled = styled.div<Props>`
  background-color: var(--palette-white);
  border-radius: 2.4rem;
  z-index: 1;
  overflow: hidden;
  margin-top: 2.2rem;
  display: flex;
  flex-direction: row;

  @media (max-width: ${tablet}px) {
    flex-direction: column;
  }

  .RepeatOrder {
    &__image {
      grid-area: image;
      background: linear-gradient(
          0deg,
          hsla(186, 43%, 16%, 0.2),
          hsla(186, 43%, 16%, 0.2)
        ),
        url("assets/images/repeat-order-background.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: flex;
      flex-direction: column;

      @media (min-width: ${tablet + 1}px) {
        border-radius: 0 0.4rem 0.4rem 0;
        width: 50%;
      }

      @media (min-width: ${tablet + 1}px) {
        height: 100%;
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      background-color: var(--palette-white);
      padding: 1.6rem 2.4rem;
      width: 50%;
      position: relative;

      @media (min-width: ${desktop}px) {
        flex-direction: row;
        width: 50%;
      }

      @media (max-width: ${tablet}px) {
        width: 100%;
      }

      @media ((min-width: ${tablet + 1}px) and (max-width: ${desktop}px)) {
        gap: 2rem;
      }
    }

    &__cycle {
      position: absolute;
      z-index: 1;
      top: 1.2rem;
      left: 1.6rem;
    }

    &__cycle--alt {
      display: none;
    }

    &__subtitle {
      display: none;
    }

    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &__title {
      font-size: 2.8rem;
      font-weight: 900;
      padding-inline: 6.4rem;
      padding-block: 4rem;
      color: var(--palette-white);
      text-shadow: 0 0.4rem 0.4rem var(--palette-black-s0-a25);
      max-width: 48rem;
      margin-inline: auto;
      text-align: center;

      @media (min-width: ${tablet + 1}px) {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
      }

      @media (max-width: ${tablet}px) {
        font-size: 2.4rem;
        padding-inline: 2.4rem;
      }
    }

    &__order-description {
      padding: 0.8rem 0;
      padding-top: 1.6rem;
      font-size: 2rem;
      font-weight: 600;
      color: var(--palette-black-s0-l10);

      @media (max-width: ${tablet + 1}px) {
        padding: 0;
      }
    }

    &__order-id {
      color: var(--palette-gray-s0-l35);
      font-size: 1.2rem;

      @media (max-width: ${tablet + 1}px) {
        padding: 0.8rem 0;
        padding-bottom: 2.4rem;
      }
    }

    &__food-logo {
      position: relative;
      padding: 0;
      width: auto;

      &--mobile {
        display: none;

        @media (max-width: ${tablet + 1}px) {
          display: block;
          position: absolute;
          top: -3.6rem;
        }
      }
    }

    &__buttons {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-end;

      &--repeat {
        align-self: center;
        width: 88%;
        padding: 1.6rem 2.6rem;
        border-radius: 8rem;
        font-size: 1.6rem;
        box-shadow: none;
        font-weight: 700;

        @media (max-width: ${tablet + 1}px) {
          width: 100%;
        }
      }
    }
  }
`;

export default RepeatOrderStyled;
